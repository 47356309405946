<template>
  <div class="top">
    <div class="left-title col">
      <div class="title">
        {{title}} {{appointment || ''}}
        <div
          v-if="createdAt"
          style="
            height:100%;
            display:flex;
            align-items:center;
            font-size: 16px;
            color:#808080;
            font-weight:bold;
          "
        >
          <el-divider direction="vertical" />
          <span>{{ createdAtFormatted }}</span>
        </div>
      </div>
      <router-link
        v-if="patientName"
        :to="`/admin/patient/full-profile/${patientId}`"
        tag="a"
        class="patient-name"
      >
        {{ patientName }}
        -
        ({{ patientId }})
      </router-link>
      <P class="subtitle">
        {{label}}
      </P>
    </div>

    <div v-if="isFullProfile" class="right col" style="height:100%;max-width: 250px">
      <div class="row">
        <el-button
          type="primary"
          style="background-color: #e9e9e9;border: 0px;"
          @click="goBack()"
          :disabled="disabled"
        >
          VOLVER
        </el-button>
        <div class="img-cont">
          <div class="col">
            <img
              style="cursor: pointer"
              @click="$emit('show-history')"
              draggable="false"
              src="@/assets/icon-history.png"
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="right col" style="height:100%;max-width: 600px">
      <div class="row">
        <div class="status">
          <el-select
            v-model="status.id"
            placeholder="Estado"
            empty="Sin Espeficar"
            @change="(e) => onStatusChange(e)"
          >
            <el-option
              v-for="(option, optIdx) in statusOptions"
              :key="`${option}-${optIdx}`"
              :label="option.status"
              :value="option.id"
            >
              <span
                style="
                  height: 100%;
                  width: 5px;
                  position: absolute;
                  left: 0px;
                "
                :style="{ 'background-color': option.colorCode }"
              />
              <span>{{ option.status }}</span>
            </el-option>
          </el-select>
          <el-tooltip
            class="item"
            effect="dark"
            :content="status.status || 'Sin Clasificar'"
            placement="top"
          >
            <div
              class="color-code-badge"
              :style="{
                'background-color': status.colorCode || '#f0f0f0'
              }"
            />
          </el-tooltip>
        </div>
        <el-button
          v-if="enableOftaDialog"
          type="primary"
          style="background-color: #6993FF;color:#fff;"
          :disabled="disabled"
          v-on:click="oftaDialogVisible = true">
          Perfil Médico
        </el-button>
        <el-button
          v-if="enableOptoDialog"
          type="primary"
          style="background-color: #6993FF;color:#fff;"
          :disabled="disabled"
          v-on:click="optoDialogVisible = true">
          Perfil Optométrico
        </el-button>
        <el-button
          v-if="role !== 'TS'"
          type="primary"
          style="background-color: #6993FF;color:#fff;"
          :disabled="disabled"
          v-on:click="$emit('before-submit')">
          {{btnText}}-{{ role }}
        </el-button>
        <el-button
          type="primary"
          style="background-color: #e9e9e9;border: 0px;"
          :disabled="disabled"
          @click="goBack()"
        >
          VOLVER
        </el-button>
      </div>
    </div>
    <OptoDialog
      id="dialog"
      :patientId="patientId"
      v-on:close-dialog="onCloseOptoDialog"
      :visible="optoDialogVisible"
    />
    <OftaDialog
      id="dialog-ofta"
      :patientId="patientId"
      v-on:close-dialog="onCloseOftaDialog"
      :visible="oftaDialogVisible"
    />
  </div>
</template>

<script>
import moment from 'moment';
import OptoDialog from './OptoDialog';
import OftaDialog from './OftaDialog';
import { baseURL } from '../API';

export default {
  name: 'Title2',
  props: {
    appointment: Number,
    appointmentType: {
      type: String,
      default: '',
    },
    isFullProfile: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    label: {
      type: String,
    },
    btnText: {
      type: String,
      default: 'AGENDAR',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableOptoDialog: {
      type: Boolean,
      default: false,
    },
    print: {
      type: Boolean,
      default: false,
    },
    enableOftaDialog: {
      type: Boolean,
      default: false,
    },
    createdAt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      role: null,
      patientName: '',
      optoDialogVisible: false,
      oftaDialogVisible: false,
      status: {
        colorCode: '',
        id: null,
        status: 'Sin clasificar',
      },
      statusOptions: [],
    };
  },
  components: {
    OptoDialog,
    OftaDialog,
  },
  computed: {
    patientId() {
      return this.$route.params.id;
    },
    downloadURL() {
      return `${baseURL}patient/reports/${this.patientId}`;
    },
    createdAtFormatted() {
      let output = '';
      if (this.createdAt) {
        output = moment(this.createdAt).format('DD/MM/YYYY');
      }
      return output;
    },
  },
  methods: {
    downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    readFile() {
      window.open('formatos.pdf', '_blank');
    },
    getRole() {
      // get role from local storage
      const localAuth = localStorage.getItem('vuex');
      // const localAppointmentType = localStorage.getItem('appointmentsTypeSelected');
      const user = JSON.parse(localAuth);
      // const appointmentType = JSON.parse(localAppointmentType);

      const userRole = user.auth.user.roleId;
      // const { userId } = appointmentType;
      this.role = userRole;
    },
    goBack() {
      // get role from local storage
      const localAuth = localStorage.getItem('vuex');
      const localAppointmentType = localStorage.getItem('appointmentsTypeSelected');

      if (!localAppointmentType) {
        this.$router.go(-1);
        return;
      }

      const user = JSON.parse(localAuth);
      const appointmentType = JSON.parse(localAppointmentType);
      const userRole = user.auth.user.roleId;

      if (userRole === 'TS') {
        // this.$router.push({
        //   name: 'cardAppointmentSocial',
        //   params: {
        //     id: '200',
        //     userId,
        //   },
        // });
        this.$router.go(-1);
        return;
      }
      if (userRole === 'OPTO') {
        this.$router.push({
          name: 'cardAppointment',
          params: {
            id: appointmentType.tipo,
          },
        });
        return;
      }
      this.$router.go(-1);
    },
    getPatientName() {
      if (this.patientId) {
        this.axios.get(`patient/info/${this.patientId}`).then((response) => {
          const { data } = response;
          if (data) {
            this.patientName = `${data.firstName} ${data.lastName}`;
          }
        });
      }
    },
    async getStatuses() {
      try {
        this.loading = true;
        const response = await this.axios.get('patient/status/');
        const { data } = response;
        this.statusOptions = data;
      } catch (error) {
        // this.$errorNotify(error);
      } finally {
        this.loading = false;
      }
    },
    async getPatientStatus() {
      try {
        this.loading = true;
        let response = null;
        if (this.appointmentType !== 'social') {
          response = await this.axios.get(`patient/status/${this.patientId}/${this.appointment}`);
        } else {
          response = await this.axios.get(`patient/status/social/${this.patientId}/${this.appointment}`);
        }
        const { data } = response;
        if (data) this.status = data;
      } catch (error) {
        // this.$errorNotify(error);
      } finally {
        this.loading = false;
      }
    },
    async onStatusChange(statusId) {
      try {
        const statusObj = this.statusOptions.find((x) => x.id === statusId);
        let response = null;
        if (statusObj) {
          if (this.status.colorCode === '') {
            response = await this.axios.post(`patient/status/${this.patientId}/${this.appointment}`, {
              statusId,
            });
          } else {
            response = await this.axios.put(`patient/status/${this.patientId}/${this.appointment}`, {
              statusId,
            });
          }
          const { data } = response;
          response = await this.axios.post(`patient/changelog/${this.patientId}`, {
            title: 'Semáforo',
            message: `Se ha actualizado el estado del paciente a "${statusObj.status}"`,
          });
          this.$notify({
            type: 'success',
            message: data.message,
          });
          this.status = statusObj;
        }
      } catch (error) {
        // this.$errorNotify(error);
      }
    },
    onCloseOptoDialog() {
      this.optoDialogVisible = false;
    },
    openOptoDialog() {
      this.optoDialogVisible = true;
    },
    onCloseOftaDialog() {
      this.oftaDialogVisible = false;
    },
    openOftaDialog() {
      this.oftaDialogVisible = true;
    },
  },
  async created() {
    this.getRole();
    await this.getPatientName();
    await this.getStatuses();
    await this.getPatientStatus();
  },
  watch: {
    patientId(val) {
      if (val) {
        this.getPatientName();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  z-index: auto;
  background-color: #fff!important;
  min-height: 60px;
  height: auto;
  padding: 0px 20px;
  #dialog {
    z-index: 10000 !important;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .status {
    width: 160px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .color-code-badge {
      background-color: #f0f0f0;
      width: 100%;
      height: 10px;
      position: absolute;
      bottom: 0px;
    }
  }
  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .left-title {
    background-color: #fff!important;
    float: left;
    height: 100%;
    .title {
      display: flex;
      color: #006EFF;
      font-weight: bold;
      font-size: 15px!important;
      margin: 0px;
    }
    .patient-name {
      margin: 0px;
      font-weight: bold;
      color: #18CD3B;
    }
    .subtitle {
      color: #646D82;
      font-weight: normal;
      font-size: 12px;
      margin: 0px;
    }
  }
  .right {
    float: right;
    .el-button {
      color: #787878;
      height: 30px;
      padding: 5px 10px;
    }
    img {
      width: 40px;
    }
  }
  .el-button.el-button--primary.is-circle {
    background-color: #6993FF;
  }
}
</style>
