<template>
  <el-row class="row" id="ofta-dialog">
    <el-dialog
      id="el-ofta-dialog"
      :visible="visible"
      v-if="patientId"
      width="600px"
      :close-on-press-escape="false"
      :show-close="false">
      <Title
        v-on:close-dialog="$emit('close-dialog')"
        :text="'PERFIL OPTOMÉTRICO'"
        :editable="false"
        :patientId="patientId"
        url="admin/evaluations/opto"
      />
      <div class="right-content">
        <Profile
          :patientId="patientId"
          :isFullProfile="true"
        />
      </div>
    </el-dialog>
  </el-row>
</template>

<script>
import Profile from '../views/profiles/Ofta';
import Title from './Title';

export default {
  name: 'OptoDialog',
  props: {
    patientId: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Profile,
    Title,
  },
};
</script>

<style lang="scss">
  #el-ofta-dialog.el-dialog__wrapper {
    .el-dialog {
      z-index: 3000 !important;
      .el-dialog__header {
        padding: 0 !important;
      }
      .el-dialog__body {
        padding-top: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
</style>
